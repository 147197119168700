<template>
  <el-dialog
    :before-close="() => $emit('close')"
    v-model="visible"
    :title="`支付宝支付￥${product?.price}`"
  >
    <div v-if="loading">加载中...</div>
    <qrcode-vue v-else-if="qrcodeSrc" :value="qrcodeSrc"></qrcode-vue>
    <div style="margin-top: 30px; color: black;">
      <div style="font-weight: bold; border-bottom: 1px solid black;">
        支付后解锁：
      </div>
      <div v-for="(item, i) in product?.items || []" :key="i">
        {{ item.name }}
      </div>
    </div>
    <div v-if="isDev" style="" @click="markAsPaid">
      标记成付款
    </div>
  </el-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Alipay",
  props: {
    product: Object
  },
  components: {
    QrcodeVue
  },
  data() {
    return {
      qrcodeSrc: null,
      loading: false,
      visible: true,
      payWatcher: null,
      orderId: null,
      loadingWatch: false,
      isDev: process.env.VUE_APP_ENV === "development"
    };
  },
  mounted() {
    this.loadAlipay();
  },
  beforeUnmount() {
    clearInterval(this.payWatcher);
  },
  methods: {
    loadAlipay() {
      this.loading = true;
      this.$axios
        .get(`/create-order?product_id=${this.product?.id}`, {
          headers: {
            Authorization: `bearer ${localStorage.getItem("token")}`
          }
        })
        .then(({ data }) => {
          console.log("payment url: ", data.payment_url);
          this.qrcodeSrc = data.redirect_url;
          this.orderId = data.order_id;
          this.loading = false;
          this.startWatcher();
        });
    },
    watchStatus() {
      if (!this.loadingWatch) {
        this.loadingWatch = true;
        this.$axios
          .get(`/order-status/${this.orderId}`, {
            headers: {
              Authorization: `bearer ${localStorage.getItem("token")}`
            }
          })
          .then(({ data }) => {
            if (data.status) {
              // paid
              this.$store.commit("PAID_HANDLER", this.product);
              this.$emit("paid");
              alert("支付成功");
              clearInterval(this.payWatcher);
            } else {
              // unpaid
            }
          });
        this.loadingWatch = false;
      }
    },
    startWatcher() {
      this.payWatcher = setInterval(() => {
        this.watchStatus();
      }, 1000);
    },
    markAsPaid() {
      this.$axios.get(`payment-notify/dev/${this.orderId}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
