<template>
  <div></div>
</template>

<script>
export default {
  name: "LogoutView.vue",
  mounted() {
    localStorage.removeItem("token");
    this.$router.push({ name: "home" });
  }
};
</script>

<style scoped></style>
