<template>
  <div class="home ">
    <Alipay
      @paid="paidHandler"
      @close="stopToPay"
      v-if="!!payProduct"
      :product="payProduct"
    ></Alipay>
    <div class="text-center" style="margin-bottom: 20px; ">
      <a href="https://wumadev.com" target="_blank"
        ><img
          alt="Wumadev无码开发"
          width="100"
          src="https://wumadev.com/wp-content/uploads/2022/09/wumadev-logo.webp"
      /></a>
    </div>
    <div class="text-center" style="margin-bottom: 30px;">
      <div style="font-weight: bold; margin-bottom: 4px;font-size: 20px;">
        内容下载目录
      </div>
      <div>所得收入将会用于维护wumadev.com的日常运营。</div>
    </div>
    <div
      v-for="product in products"
      :key="product.id"
      class=""
      style="margin-bottom: 50px; "
    >
      <div
        class=""
        style="display: flex; align-items: center; flex-wrap: wrap; border-bottom: 1px solid black; padding-bottom: 10px; margin-bottom: 10px;"
      >
        <span style="font-size: 20px; font-weight: bolder; color: #9d0208;">{{
          product.title
        }}</span>
        <span
          v-if="!(user?.paids || []).includes(product.id)"
          @click="e => startToPay(product)"
          style="margin-left: auto; background-color: #9d0208; color: white; padding: 4px 8px; cursor: pointer;"
          >￥{{ product.price }}支付宝解锁</span
        >
      </div>
      <div
        v-for="(item, i) in product.items"
        :key="`item-${product.id}-${i}`"
        style="margin-bottom: 10px;"
      >
        <div style="font-weight: bold;">{{ i + 1 }}.{{ item.name }}</div>
        <div>{{ item.description }}</div>
        <div v-if="item.image">
          <img
            :src="`https://notes-strapi.xmgseo.com${item?.image?.url}`"
            alt=""
            style="width: 150px;"
          />
        </div>
        <div
          v-if="(user?.paids || []).includes(product.id)"
          style="text-align: right"
        >
          <a :href="item.src" target="_blank"
            ><button
              style="cursor: pointer; padding: 2px 4px; background-color: #9d0208; color: white; border: none; box-shadow: none; outline: none;"
            >
              下载
            </button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alipay from "@/components/Alipay";
export default {
  name: "HomeView",
  data() {
    return {
      payProduct: null
    };
  },
  computed: {
    ...mapGetters(["user", "products"])
  },
  components: {
    Alipay
  },
  methods: {
    startToPay(product) {
      if (this.user) {
        this.payProduct = product;
      } else {
        this.$router.push("/login");
      }
    },
    stopToPay() {
      this.payProduct = null;
    },
    paidHandler() {
      this.payProduct = null;
    }
  },
  mounted() {
    this.$axios.get(`/products`).then(res => {
      if (res.data) {
        this.$store.commit("UPDATE_PRODUCTS", res.data);
      }
    });
  }
};
</script>
