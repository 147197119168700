<template>
  <nav class="text-center">
    <router-link to="/">首页</router-link> |
    <router-link v-if="!user" to="/login">登录</router-link>
    <span style="margin-right: 4px;" v-if="user"
      >{{ user?.phone.slice(0, 3) }}***</span
    >
    <router-link v-if="user" to="/logout">退出</router-link>
  </nav>
  <div style="max-width: 500px; margin: 0 auto; padding: 0 10px;">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$axios
      .get(`/me`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`
        }
      })
      .then(res => {
        if (res.data.phone) {
          this.$store.commit("UPDATE_USER", res.data);
        }
      });
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
.text-center {
  text-align: center;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: black;

    &.router-link-exact-active {
      color: #9d0208;
    }
  }
}
</style>
