import { createStore } from "vuex";

export default createStore({
  state: {
    user: null,
    products: []
  },
  getters: {
    user: state => state.user,
    products: state => state.products
  },
  mutations: {
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_PRODUCTS(state, payload) {
      state.products = payload;
    },
    PAID_HANDLER(state, product) {
      state.user = { ...state.user, paids: [...state.user.paids, product.id] };
    }
  },
  actions: {},
  modules: {}
});
