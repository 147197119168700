import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus);

app.config.globalProperties.$axios = axiosInstance;
app.mount("#app");
